/**
 * @since 1.0.0
 * @version 1.7.3
 */

jQuery(($) => {

  //Declare dataLayer if not set
  window.dataLayer = window.dataLayer || []

  jQuery(document).on('gform_confirmation_loaded', (e, formId) => {
    window.dataLayer.push({
      event: 'gformConfirmationLoaded',
      formName: TG.gravityForms.find( form => form.id === formId).name
    })
  })
})
