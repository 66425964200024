jQuery(document).ready(($) => {
  const widgets = jQuery(document).find('.widget')

  for (const widget of widgets) {
    let widgetId = widget.id
    let widgetLabelSuffix = `${widgetId}-title`

    jQuery(`#${widgetId}`)
      .children('.js-widget-title')
      .attr('id', `${widgetLabelSuffix}`)

    jQuery(`#${widgetId}`).attr('aria-labelledby', `${widgetLabelSuffix}`)
  }
})
