jQuery(($) => {
  $('.widget_nav_menu .menu').each(function () {
    $(this).addClass('list list-icons list-icons-sm')
  })

  $('.widget_nav_menu .menu a').each(function () {
    $(this).prepend(`<i class="fas fa-angle-right"></i>`)
  })

  // remove custom list styles from footer copyright
  $('.footer-copyright .widget_nav_menu .menu').each(function () {
    $(this).removeClass('list list-icons list-icons-sm')
    $(this).addClass('list list-unstyled mb-0')
  })

  $('.footer-copyright .widget_nav_menu .menu li i').each(function () {
    $(this).remove()
  })
})
