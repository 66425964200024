function firstWordExtraBold(element) {
  $(element).each(function () {
    let allWords = $(this).text().split(' ')
    let firstWord = allWords[0]

    firstWord = `<span class="font-weight-extra-bold">${firstWord}</span>`

    allWords.shift()
    allWords.unshift(firstWord)
    let joined = allWords.join(' ')

    $(this).html(joined)
  })
}

export { firstWordExtraBold }
