jQuery(($) => {
  $('#mainNav .has-dropdown').on('click', function () {
    $(this).children('.js-dropdown-menu').toggleClass('active')
    $(this)
      .children('.js-dropdown-toggle')
      .attr('aria-expanded', (index, attr) => {
        return attr == 'false' ? 'true' : 'false'
      })
  })
})
