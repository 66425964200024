jQuery(($) => {
  const NAV_STICKY_START_AT = $('#header').data('plugin-options').stickyStartAt

  function menuScrolled() {
    if ($(window).scrollTop() <= NAV_STICKY_START_AT) {
      $('#header .js-menu-row').each(function () {
        $(this).removeClass('d-none')
        $(this).attr('style', '')
      })

      return
    }

    $('#header .js-menu-row').each(function () {
      $(this).addClass('d-none')
    })

    $('#header .js-scroll-menu-row').each(function () {
      $(this).removeClass('d-none')
      $(this).css('position', 'relative')
      $(this).css('top', $(this).data('position-offset'))
    })
  }

  $(window).on('scroll', () => {
    menuScrolled()
  })

  menuScrolled()
})
