jQuery(($) => {
  const $SIDEBAR_CONTAINER = $('#sidebar')
  const HASH_OFFSET = 95
  let headingContent = ''
  let headingContentStart = `<ul class="nav nav-list flex-column mb-5">`
  let headingContentEnd = `</ul>`
  let allElements = $('.js-sticky-sidebar-content *')
  let allHeadings = []

  headingContent += headingContentStart

  for (let i = 0; i < allElements.length; i++) {
    switch (allElements[i].tagName.toLowerCase()) {
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        allHeadings.push([
          allElements[i].tagName.toLowerCase(),
          allElements[i].innerText
        ])

        $(allElements[i]).attr('id', formatAnchorText(allElements[i].innerText))
        break
    }
  }

  let prevElement = ''
  for (let i = 0; i < allHeadings.length; i++) {
    let headingTag = allHeadings[i][0]
    let headingText = allHeadings[i][1]

    if (prevElement != '') {
      // level 2
      if (prevElement == 'h2' && headingTag == 'h3') {
        headingContent += '<ul>'
        headingContent += getListItemHtml(headingText)
      } else if (prevElement == 'h3' && headingTag == 'h2') {
        headingContent += '</ul>'
        headingContent += getListItemHtml(headingText)
        // level 3
      } else if (prevElement == 'h3' && headingTag == 'h4') {
        headingContent += '<ul>'
        headingContent += getListItemHtml(headingText)
      } else if (prevElement == 'h4' && headingTag == 'h3') {
        headingContent += '</ul>'
        headingContent += getListItemHtml(headingText)
        // level 4
      } else if (prevElement == 'h4' && headingTag == 'h5') {
        headingContent += '<ul>'
        headingContent += getListItemHtml(headingText)
      } else if (prevElement == 'h5' && headingTag == 'h4') {
        headingContent += '</ul>'
        headingContent += getListItemHtml(headingText)
        // level 5
      } else if (prevElement == 'h5' && headingTag == 'h6') {
        headingContent += '<ul>'
        headingContent += getListItemHtml(headingText)
      } else if (prevElement == 'h6' && headingTag == 'h5') {
        headingContent += '</ul>'
        headingContent += getListItemHtml(headingText)
      } else {
        headingContent += getListItemHtml(headingText)
      }
    } else {
      headingContent += getListItemHtml(headingText)
    }

    prevElement = headingTag
  }

  headingContent += headingContentEnd
  $($SIDEBAR_CONTAINER).append(headingContent)

  function getListItemHtml(text) {
    return `<li class="nav-item">${getLinkHtml(text)}</li>`
  }

  function getLinkHtml(text) {
    let anchorText = formatAnchorText(text)

    return `<a class="nav-link" href="#${anchorText}" data-hash data-hash-offset="${HASH_OFFSET}">${text}</a>`
  }

  function formatAnchorText(text) {
    text = text.toLowerCase()
    text = text.replace(/ /g, '-')
    text = text.replace(/[^a-zA-Z-]/g, '') // replace anything that is NOT a-z, A-Z, or - (hyphen)

    return text
  }

  // Code copied from template plugin
  // used the On Load Scroll
  // running it again when clicked
  $(document.body).on('click', '#sidebar a', (event) => {
    $('html, body').animate(
      {
        scrollTop: $(window).scrollTop()
      },
      1
    )

    setTimeout(() => {
      let target = window.location.hash,
        offset = $(window).width() < 768 ? 180 : 90

      if (!$(target).length) {
        return
      }

      if ($(`a[href$='${window.location.hash}']`).is('[data-hash-offset]')) {
        offset = parseInt(
          $(`a[href$='${window.location.hash}']`)
            .first()
            .attr('data-hash-offset')
        )
      } else if ($('html').is('[data-hash-offset]')) {
        offset = parseInt($('html').attr('data-hash-offset'))
      }

      if (isNaN(offset)) {
        offset = 0
      }

      $('body').addClass('scrolling')

      $('html, body').animate(
        {
          scrollTop: $(target).offset().top - offset
        },
        600,
        'easeOutQuad',
        () => {
          $('body').removeClass('scrolling')
        }
      )
    }, 50)
  })
})
