jQuery(($) => {
  $('.widget_categories ul').each(function () {
    $(this).addClass('nav nav-list flex-column mb-5')
  })

  $('.widget_categories ul li').each(function () {
    $(this).addClass('nav-item')
  })

  $('.widget_categories ul li a').each(function () {
    $(this).addClass('nav-link')
  })
})
